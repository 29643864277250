import Swiper from "swiper";
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	const SwiperFeed_1 = new Swiper("[Swiper='feedbacks-1']", {
		slidesPerView: 1.3,
		speed: 1000,
		centeredSlides: true,
		spaceBetween: 20,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		loop: true,
		breakpoints: {
			1900: {
				slidesPerView: 3.5,
				spaceBetween: 32,
			},
			800: {
				slidesPerView: 2.5,
				spaceBetween: 32,
			},

		}


	});

	const SwiperFeed_2 = new Swiper("[Swiper='feedbacks-2']", {
		slidesPerView: 1.3,
		speed: 1000,
		centeredSlides: false,
		spaceBetween: 20,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			reverseDirection: true,
		},
		loop: true,
		breakpoints: {
			1900: {
				slidesPerView: 3.5,
				spaceBetween: 32,
			},
			800: {
				slidesPerView: 2.5,
				spaceBetween: 32,
			},

		}


	}); 



	console.log('Home')
}