import { find } from "../utils/dom";
import Swiper from "swiper";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const Sobre = () => {
    const page = find("[js-page='sobre']");
    if (!page) return;

    //Swiper
    const SwiperGallery = new Swiper("[js-swiper='gallery-sobre']", {
        slidesPerView: "auto",
        spaceBetween: 25,
        loop: true,
        freeMode: true,
        speed: 500,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        }

    })

    Fancybox.bind('[data-fancybox="gallery"]', {
        // Your custom options
    });
}

export default Sobre;