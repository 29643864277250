import { find } from "../utils/dom";


const CollapseTratamentos = () => {


    const Colunas = document.querySelectorAll("[js-collapse] h4");

    if (!Colunas) return;

    let i = 0;

    for (i = 0; i < Colunas.length; i++) {
        Colunas[i].addEventListener("click", function () {
            this.classList.toggle("active");


            let content = this.nextElementSibling;
            content.classList.toggle("active");
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        });
    }

}

export default CollapseTratamentos;