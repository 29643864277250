import { find } from "../utils/dom";

const Blog = () => {
    const page = find("[js-page='blog']");
    const CardHighlight = find("[js-blog='highlight'");

    if (!page) return;

    const URL = document.URL;
    if (URL.indexOf("?page=") > -1 && URL.indexOf("?page=1") > -1) {
        CardHighlight.classList.remove("hidden");
    }

    console.log(document.URL);
}

export default Blog;