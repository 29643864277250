import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import PageCorpoClinico from "./pages/Single_Corpo_Clinico";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import Menu from './modules/menu';
import CollapseTratamentos from './modules/collapse';
import Single_Blog from './pages/SingleBlog';
import Aos from 'aos';
import Sobre from './pages/Sobre';
import Blog from './pages/Blog';
import EditorJS from './modules/editorJS';
// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
Menu();
CollapseTratamentos();

// Pages
PageHome()
PageCorpoClinico()
PageLogin()?.init()
Single_Blog()
Blog();
Sobre();

// utils logs
logs()

EditorJS();

Aos.init({
    disable: function () {
        var maxWidth = 768;
        return window.innerWidth < maxWidth;
    },
    startEvent: 'DOMContentLoaded',
    offset: 30, // offset (in px) from the original trigger point
    delay: 100, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
});

