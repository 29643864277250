import { find } from "../utils/dom";
import Swiper from "swiper";

const Single_Blog = () => {
    const page = find("[js-page='single_blog']");
    if (!page) return;

    const SwiperBlog = new Swiper("[js-swiper='blog']", {
        slidesPerView: 1.6,
        spaceBetween: 32,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        loop: true,
        breakpoints: {
            420: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            640: {
                slidesPerView: 2.3,
            },
            1024: {
                slidesPerView: 3,
            },

        },
        on: {
            init() {

            },
        },
    });
}

export default Single_Blog;