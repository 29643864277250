import { find } from "../utils/dom"
import Swiper from "swiper";


const Single_Corpo_Clinico = () => {
    const page_single_corpo_clico = find("[js-page='corpo_clinico']")

    if (!page_single_corpo_clico) return;


    const BTNext = find("[js-swiper='next']");
    const BTPrev = find("[js-swiper='prev']");

    const SwiperEspecialistas = new Swiper("[js-swiper='swiper-especialistas']", {
        slidesPerView: 2,
        spaceBetween: 20,
        speed: 500,
        navigation: {
            nextEl: BTNext,
            prevEl: BTPrev,
        },
        breakpoints: {
            600: {
                slidesPerView: 3,
            },
            767: {
                slidesPerView: 4,
            },

            1281: {
                slidesPerView: 3,
            },

        },
    });

    const atualSlide = document.querySelector(".active-slide-here");
    const indexatualSlide = atualSlide.attributes["aria-label"].value;
    SwiperEspecialistas.slideTo(parseInt(indexatualSlide.charAt(0)) - 1)


}

export default Single_Corpo_Clinico