import { find } from "../utils/dom";

const Menu = () => {


    const DebugMenu = {
        Logo: (Menu, Top) => {
            const WidthMedia = window.matchMedia("(max-width: 1180px)").matches;

            if (WidthMedia) {
                Menu.style.transform = `translateY(-${Top}px)`;
            }
        }
    }


    function is_nav_tratamentos(WidthMedia) {
        if (WidthMedia) {
            BT_Tratamentos.addEventListener("click", (event) => {

                Menu_Tratamentos.classList.remove("md:translate-x-[-100vw]");
                Menu_Tratamentos.classList.add("md:translate-x-[0]");

                Value_Nav.value = "open";
                Value_Button_mobile.value = "open";
            });
        }
    }


    function HeightSize() {
        const posicaoY = window.pageYOffset || window.scrollY;
        const WidthMedia = window.matchMedia("(min-width: 1180px)").matches;
        const HeaderPai = find("[js-header='1']");

        if (WidthMedia && HeaderPai) {
            if (posicaoY > 88) {
                HeaderPai.classList.add("is_scroll");
            }
            else {
                HeaderPai.classList.remove("is_scroll");
            }
        }
        else {
            //HeaderPai.style.height = "auto";
        }
    }







    const Nav = find("[js-nav-mobile]");
    const botao_menu_mobile = find("[js-button-mobile]");

    const Header = find("[js-nav='header']");

    const Menu_Tratamentos = find("[js-menu='tratamentos']");
    const BT_Tratamentos = find("[js-button='tratamentos']");

    const OpcoesMenuMobile = document.querySelectorAll("[js-menu-option-mobile] li");


    /** Logo do site */
    const Logo = find("[js-logo='principal']");


    if (!Nav || !botao_menu_mobile) return;

    const Value_Nav = Nav.attributes["js-nav-mobile"];
    const Value_Button_mobile = botao_menu_mobile.attributes["js-button-mobile"];

    if (OpcoesMenuMobile) {
        OpcoesMenuMobile.forEach((option) => {
            option.addEventListener("click", (event) => {
                let WidthMedia = window.matchMedia("(max-width: 1180px)").matches;

                if (!event.target.classList.contains('is_drop') && WidthMedia) {
                    Nav.classList.add("translate-x-[-100vw]");
                    Nav.classList.remove("translate-x-[0]");

                    // Fechando o menu de Tratamentos
                    if (Menu_Tratamentos) {
                        Menu_Tratamentos.classList.add("md:translate-x-[-100vw]");
                        Menu_Tratamentos.classList.remove("md:translate-x-[0]");
                    }

                    Value_Nav.value = "closed";
                    Value_Button_mobile.value = "closed";

                    Logo.classList.remove("menu_open");
                } else {
                    event.target.classList.toggle("open");
                }
            });
        })
    }

    /** Evento de abrir e fechar o(s) menu(s) */
    botao_menu_mobile.addEventListener("click", (event) => {

        if (Value_Nav.value == "closed") {
            Nav.classList.remove("translate-x-[-100vw]");
            Nav.classList.add("translate-x-[0]");


            Value_Nav.value = "open";
            Value_Button_mobile.value = "open";

            Logo.classList.add("menu_open");

        } else if (Value_Nav.value == "open") {
            Nav.classList.add("translate-x-[-100vw]");
            Nav.classList.remove("translate-x-[0]");

            // Fechando o menu de Tratamentos
            if (Menu_Tratamentos) {
                Menu_Tratamentos.classList.add("md:translate-x-[-100vw]");
                Menu_Tratamentos.classList.remove("md:translate-x-[0]");
            }

            Value_Nav.value = "closed";
            Value_Button_mobile.value = "closed";

            Logo.classList.remove("menu_open");
        }

    })

    /** Evento de scroll */
    document.addEventListener("scroll", () => {
        HeightSize();


        DebugMenu.Logo(Logo, window.scrollY);
    })
    window.addEventListener("resize", () => {
        HeightSize();

        DebugMenu.Logo(Logo, window.scrollY);
    })

    document.addEventListener("DOMContentLoaded", () => {
        HeightSize();


        DebugMenu.Logo(Logo, window.scrollY);
    })



    // Botao tratamentos
    if (BT_Tratamentos) {
        let WidthMedia = window.matchMedia("(max-width: 1180px)").matches;

        is_nav_tratamentos(WidthMedia);

        window.addEventListener("resize", () => {
            WidthMedia = window.matchMedia("(max-width: 1180px)").matches;
            is_nav_tratamentos(WidthMedia);
        });
    }



}

export default Menu;